<template>
  <div class="readme-article">
    <h1 id="申请单">
      四、申请单
    </h1>
    <h2 id="赠课-学币申请">1.赠课/学币申请</h2>
    <h3 id="赠课-学币列表">1.1赠课/学币列表</h3>
    <p>查看流水列表，可以申请赠送课时或者赠送学币。</p>
    <p>
      <img src="@/assets/img/student/4-1.png">
    </p>
    <h3 id="申请赠课-学币">1.2申请赠课/学币</h3>
    <p>查看流水详情，选择类型，填写赠送量，备注提交申请。</p>
    <p>
      <img src="@/assets/img/student/4-2.png">
    </p>
    <h2 id="退款申请">2.退款申请</h2>
    <h3 id="退款申请列表">2.1退款申请列表</h3>
    <p>查看学员列表，展示学员余额，课时信息等，可申请退款。</p>
    <p>
      <img src="@/assets/img/student/4-3.png">
    </p>
    <h3 id="提交退款申请">2.2提交退款申请</h3>
    <p>填写退款金额，选择学员状态以及备注提交申请。</p>
    <p>
      <img src="@/assets/img/student/4-4.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Student4-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>